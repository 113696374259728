import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import QuotaApi from '../../../services/api/CampaignQuotaService'
import { ApiMetadata, Quota } from '../../../types'

interface QuotaState {
  campaignQuotas: Quota[]
  loading: boolean
  error: string | null
  metaData: ApiMetadata
  totalQuota: number
}

const initialState: QuotaState = {
  campaignQuotas: [],
  metaData: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  loading: false,
  error: null,
  totalQuota: 0
}
export const getAllQuota = createAsyncThunk(
  'quota/getAllQuota',
  async ({ token, perPage, page, campaignId }: { token: string, perPage: number, page: number, campaignId: string }, { rejectWithValue }) => {
    try {
      const data = await QuotaApi.getAllQuota({ token, perPage, page, campaignId })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const createQuota = createAsyncThunk(
  'quota/createQuota',
  async ({ token, campaignId, campaignQuota }: { token: string, campaignId: string, campaignQuota: { orderedQuota : number, orderedDate: string } }, { rejectWithValue }) => {
    try {
      const data = await QuotaApi.createQuota({ token, campaignId, campaignQuota })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const editQuota = createAsyncThunk(
  'quota/editQuota',
  async ({ token, campaignQuotaId, campaignQuota }: { token: string, campaignQuotaId: string, campaignQuota: { orderedQuota : number, orderedDate: string } }, { rejectWithValue }) => {
    try {
      const data = await QuotaApi.editQuota({ token, campaignQuotaId, campaignQuota })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const deleteQuota = createAsyncThunk(
  'quota/deleteQuota',
  async ({ token, campaignQuotaId }: { token: string, campaignQuotaId: string }, { rejectWithValue }) => {
    try {
      const data = await QuotaApi.deleteQuota({ token, campaignQuotaId })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const createQuotaNotification = createAsyncThunk(
  'quota/createQuotaNotification',
  async ({ token, campaignId, campaignQuotaNotification }: { token: string, campaignId: string, campaignQuotaNotification: { threshold: number, recipients: string[] } }, { rejectWithValue }) => {
    try {
      const data = await QuotaApi.createQuotaNotification({ token, campaignId, campaignQuotaNotification })
      return data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data)
    }
  }
)
const quotaSlice = createSlice({
  name: 'quota',
  initialState,
  reducers: {
    removeQuota: (state, action: PayloadAction<{ campaignQuotaId: string }>) => {
      state.loading = false
      state.campaignQuotas = state.campaignQuotas.filter(quota => quota.id !== action.payload.campaignQuotaId)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.campaignQuotas = action.payload.campaignQuotas
        state.metaData = action.payload.meta
        state.totalQuota = action.payload.totalQuota
      })
      .addCase(getAllQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(createQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(createQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(editQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(editQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(editQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(deleteQuota.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteQuota.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(deleteQuota.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(createQuotaNotification.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createQuotaNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(createQuotaNotification.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload.error
      })
  }
})
export const { removeQuota } = quotaSlice.actions
const { reducer } = quotaSlice
export default reducer
