import React, { useState } from 'react'
import { Formik } from 'formik'
import { number, object, string, array } from 'yup'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch } from '../../store/hooks'
import { createQuotaNotification } from '../../store/reducers/api/campaignQuotaReducer'
import dayjs from 'dayjs'
import { setToast } from '../../store/reducers/toastReducer'

interface QuotaNotificationEditorProps {
  token: string
  campaignId: string
  handleQuotaRefresh: () => void
  isLoading: boolean
}

const QuotaNotificationEditor = ({
  handleQuotaRefresh,
  token,
  campaignId,
  isLoading
}: QuotaNotificationEditorProps) => {
  const QuotaSchema = object().shape({
    threshold: number().min(1).max(100).required('Threshold is required'),
    recipients: array()
      .of(string().email('Invalid email'))
      .min(1, 'At least one valid recipient email is required')
  })

  const dispatch = useAppDispatch()
  const [recipients, setRecipients] = useState<string[]>([])
  const [recipientInput, setRecipientInput] = useState<string>('')

  const initialValues = {
    threshold: 1,
    recipients: []
  }

  const handleKeyDown = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    handleChange: (e: React.ChangeEvent<any>) => void,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const email = recipientInput.trim()
      if (email === '') {
        setFieldError('recipients', 'Email cannot be empty')
        return
      }
      try {
        await string().email('Invalid email').validate(email)
        if (!recipients.includes(email)) {
          const updatedRecipients = [...recipients, email]
          setRecipients(updatedRecipients)
          handleChange({ target: { name: 'recipients', value: updatedRecipients } } as React.ChangeEvent<any>)
          setRecipientInput('')
        }
      } catch (validationError: any) {
        setFieldError('recipients', validationError.message)
      }
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={QuotaSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const campaignQuotaNotification = {
            threshold: values.threshold,
            recipients
          }
          dispatch(createQuotaNotification({ token, campaignId, campaignQuotaNotification }))
            .unwrap()
            .then(() => {
              const payload = {
                title: 'Success',
                message: 'Quota notification added successfully',
                isVisible: true,
                timestamp: dayjs().format('LT'),
                type: 'success'
              }
              dispatch(setToast(payload))
              handleQuotaRefresh()
              actions.resetForm()
              setRecipients([])
              actions.setFieldValue('recipients', [])
            })
            .catch(() => {
              const payload = {
                title: 'Error',
                message: 'Failed to add Quota notification',
                isVisible: true,
                timestamp: dayjs().format('LT'),
                type: 'danger'
              }
              dispatch(setToast(payload))
            })
          actions.setSubmitting(false)
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          values,
          setFieldError
        }) => (
          <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
            <div className="">
              <div className="ms-3 mb-3">
                <label className="form-label">Notify me when remaining quota is below</label>
                <div className="input-group">
                  <input
                    type="number"
                    name="threshold"
                    min={1}
                    max={100}
                    onChange={(e) => {
                      if (Number(e.target.value) <= 100) {
                        handleChange(e)
                      }
                    }}
                    onBlur={handleBlur}
                    className={`form-control ${errors.threshold && touched.threshold ? 'is-invalid' : ''}`}
                    value={values.threshold}
                  />
                  <span className="input-group-text">%</span>
                </div>
                {errors.threshold && touched.threshold && (
                  <div className="invalid-feedback d-block">{errors.threshold}</div>
                )}
              </div>
              <div className="ms-3">
                <label className="form-label">Recipient Emails</label>
                <input
                  type="email"
                  name="recipientInput"
                  onKeyDown={(e) => handleKeyDown(e, handleChange, setFieldError)}
                  onBlur={handleBlur}
                  className="form-control"
                  value={recipientInput}
                  onChange={(e) => setRecipientInput(e.target.value)}
                />
                {(errors.recipients || touched.recipients) && (
                  <div className="invalid-feedback d-block">{errors.recipients}</div>
                )}
                <div className="mt-2">
                  {recipients.map((email, index) => (
                    <span key={index} className="badge bg-secondary me-2 m-1">
                      {email}
                      <button
                        type="button"
                        className="btn-close btn-xs ms-2"
                        aria-label="Close"
                        style={{ filter: 'invert(1)', fontSize: '0.75rem' }}
                        onClick={() => {
                          const updatedRecipients = recipients.filter((_, i) => i !== index)
                          setRecipients(updatedRecipients)
                          handleChange({ target: { name: 'recipients', value: updatedRecipients } } as React.ChangeEvent<any>)
                        }}
                      ></button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={isLoading}
              >
                <i className="bi bi-save text-white"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default QuotaNotificationEditor
