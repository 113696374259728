import React from 'react'
import { Formik } from 'formik'
import { number, object, date } from 'yup'
import Calendar from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch } from '../../store/hooks'
import { createQuota, editQuota } from '../../store/reducers/api/campaignQuotaReducer'
import { Quota } from '../../types'
import dayjs from 'dayjs'
import { setToast } from '../../store/reducers/toastReducer'

interface QuotaEditorProps {
  isEditMode: boolean
  token: string
  campaignId: string
  campaignQuota: Quota | null
  handleQuotaRefresh: () => void
  isLoading: boolean
}

const QuotaEditor = ({
  handleQuotaRefresh,
  isEditMode,
  token,
  campaignId,
  campaignQuota,
  isLoading
}: QuotaEditorProps) => {
  const QuotaSchema = object().shape({
    orderedDate: date().nullable().required('Ordered Date is required'),
    orderedQuota: number().min(1).label('Limit').required()
  })

  const dispatch = useAppDispatch()

  const initialValues = isEditMode
    ? {
        orderedDate: campaignQuota?.orderedDate
          ? new Date(campaignQuota?.orderedDate)
          : null,
        orderedQuota: campaignQuota?.orderedQuota || 1
      }
    : {
        orderedDate: null,
        orderedQuota: 1
      }

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={QuotaSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (isEditMode) {
            if (
              campaignQuota &&
              campaignQuota?.id &&
              token &&
              values.orderedDate
            ) {
              const updatedCampaignQuota = {
                orderedQuota: values.orderedQuota,
                orderedDate: values.orderedDate.toISOString()
              }
              dispatch(
                editQuota({
                  token,
                  campaignQuota: updatedCampaignQuota,
                  campaignQuotaId: campaignQuota?.id
                })
              )
                .unwrap()
                .then(() => {
                  const payload = {
                    title: 'Success',
                    message: 'Quota edited successfully',
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'success'
                  }
                  dispatch(setToast(payload))
                  handleQuotaRefresh()
                })
                .catch((error: any) => {
                  const payload = {
                    title: 'Error',
                    message: `Failed to edit quota: ${error.message}`,
                    isVisible: true,
                    timestamp: dayjs().format('LT'),
                    type: 'danger'
                  }
                  dispatch(setToast(payload))
                })
            }
          } else {
            if (token && campaignId) {
              if (values.orderedDate) {
                const campaignQuota = {
                  orderedQuota: values.orderedQuota,
                  orderedDate: values.orderedDate.toISOString()
                }
                dispatch(createQuota({ token, campaignQuota, campaignId }))
                  .unwrap()
                  .then(() => {
                    const payload = {
                      title: 'Success',
                      message: 'Quota added successfully',
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'success'
                    }
                    dispatch(setToast(payload))
                    handleQuotaRefresh()
                    actions.resetForm()
                  })
                  .catch((error: any) => {
                    const payload = {
                      title: 'Error',
                      message: `Failed to add quota: ${error.message}`,
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'error'
                    }
                    dispatch(setToast(payload))
                  })
              }
            }
          }

          actions.setSubmitting(false)
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label className="form-label fw-bold">{`${
                isEditMode ? 'Edit' : 'Add'
              } Quota`}</label>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 me-3">
                <label className="form-label">Ordered Date</label>
                <Calendar
                  className={`form-control ${errors.orderedDate && touched.orderedDate ? 'is-invalid' : ''}`}
                  selected={values.orderedDate}
                  onChange={(date) => setFieldValue('orderedDate', date)}
                  dateFormat={'dd/MM/yyyy'}
                />
                {errors.orderedDate && touched.orderedDate
                  ? (
                      <div className="invalid-feedback d-block">{errors.orderedDate}</div>
                    )
                  : null
                  }
              </div>
              <div className="w-50 ms-3">
                <label className="form-label">Ordered Quota</label>
                <input
                  type="number"
                  name="orderedQuota"
                  min={1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.orderedQuota && touched.orderedQuota ? 'is-invalid' : ''}`}
                  value={values.orderedQuota}
                />
                {errors.orderedQuota && touched.orderedQuota
                  ? (
                      <div className="invalid-feedback d-block">{errors.orderedQuota}</div>
                    )
                  : null}
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={isLoading}
              >
                <i className="bi bi-save text-white"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default QuotaEditor
