import axios from 'axios'
const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export const getAllQuota = async ({
  token,
  perPage,
  page,
  campaignId
}: {
  token: string
  perPage: number
  page: number
  campaignId: string
}) => {
  const response = await apiClient.get(`/campaigns/${campaignId}/quotas`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      limit: perPage,
      page
    }
  })
  return response.data
}

export const createQuota = async ({
  token,
  campaignQuota,
  campaignId
}: {
  token: string
  campaignId: string
  campaignQuota: { orderedQuota: number; orderedDate: string }
}) => {
  const response = await apiClient.post(
    `/campaigns/${campaignId}/quotas`,
    { campaignQuota },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}
export const editQuota = async ({
  token,
  campaignQuota,
  campaignQuotaId
}: {
  token: string
  campaignQuotaId: string
  campaignQuota: { orderedQuota: number; orderedDate: string }
}) => {
  const updatedCampaignQuota = {
    orderedDate: campaignQuota.orderedDate,
    orderedQuota: campaignQuota.orderedQuota
  }
  const response = await apiClient.put(
    `/campaign-quotas/${campaignQuotaId}`,
    { campaignQuota: updatedCampaignQuota },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const deleteQuota = async ({
  token,
  campaignQuotaId
}: {
  token: string
  campaignQuotaId: string
}) => {
  const response = await apiClient.delete(
    `/campaign-quotas/${campaignQuotaId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const createQuotaNotification = async ({
  token,
  campaignId,
  campaignQuotaNotification
}: {
  token: string
  campaignId: string,
  campaignQuotaNotification:{
    threshold: number,
    recipients:string[]
  }
}) => {
  const response = await apiClient.post(
    `/campaigns/${campaignId}/quota-notifications`,
    { campaignQuotaNotification },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export default { getAllQuota, createQuota, editQuota, deleteQuota, createQuotaNotification }
